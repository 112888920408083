import Router from 'next/router';
import Link from 'next/link';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { SUCCESS_MESSAGES } from '../../../commons/feedbackMessages';
import store from '../../app/store';
import {
  addItemToCart,
  getQuote,
  placeOrder,
  updateTax,
  placeNewOrder,
  addCoupon,
  removeItemFromCart,
  getAbandonedData,
  prepareCart,
  updateProduct,
  addItemToQuote,
  quantityWarning,
} from '../cart/cartState';
import { insertSurvey } from '../survey/surveyState';
import {
  loginUser,
  resetPassword,
  updateFirstLastNames,
  resetPasswordLink,
  submitSupportTicket,
  resetPasswordV2,
  logoutUser,
} from '../user/userState';
import {
  getSubscriptionDetails,
  subscriptionPaymentDetails,
  updateSubscriptionData,
  getActiveSubscriptions,
} from '../subscription/subscriptionState';
import {
  getOrderDetails,
  getListAllOrders,
  getAllOrdersStatus,
  getOrdersEvents,
} from '../order/orderState';
import {
  getPostWithPagination,
  getPostByCategory,
  getPostListSingle,
  getPostByTag,
} from '../resource/resourceState';
import { handleUpdateSubFeedback } from '../../../commons/feedbackMessages';
import { setToken } from '../../../commons/utils/extractCustomerToken';
import { productsKeyConstants } from '../../../config';

const initialState = {
  awaitingApis: 0,
  manualLogout: false,
  showCart: false,
  cartLoading: false,
  brokenCart: false,
  hideSummary: false,
  quantityWarning: {
    id: null,
    status: false,
  },
  shippingInsights: false,
  trackingEvents: false,
  drawerShow: false,
  subscriptionStatusMsg: {},
};

const incrementCounter = (state) => (state.awaitingApis += 1);
const decrementCounter = (state) =>
  (state.awaitingApis = state.awaitingApis > 0 ? state.awaitingApis - 1 : 0);
const errorMessageForInvalidLogin =
  'The account sign-in was incorrect. Please try again or reach out to our support team for additional help.';
const quoteErrorMessage =
  "Quote for the requested quoteId doesn't exist. Please try again with valid quoteId";

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    incrementAwaitingApiCounter: (state) => {
      incrementCounter(state);
    },
    decrementAwaitingApiCounter: (state) => {
      decrementCounter(state);
    },
    isManualLogout: (state, action) => {
      state.manualLogout = action.payload;
    },
    showCart: (state, action) => {
      state.showCart = action.payload;
    },
    disableBrokenCart: (state, action) => {
      state.brokenCart = action.payload;
    },
    isHideSummary: (state, action) => {
      state.hideSummary = action.payload;
    },
    isDrawerOpen: (state, action) => {
      state.drawerShow = action.payload;
    },
    clearSubscriptionStatusMsg: (state, action) => {
      state.subscriptionStatusMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addItemToCart.pending, (state) => {
        state.showCart = true;
        state.cartLoading = true;
        // incrementCounter(state);
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.showCart = true;
        state.cartLoading = false;
        // decrementCounter(state);
        // const { RF_QUICKSTART, RF_60CT } = productsKeyConstants;
        // let canRedirect = ![RF_QUICKSTART, RF_60CT].includes(
        //   action.meta.arg.data.key
        // );
        // if (canRedirect) Router.push('/checkout');
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        // decrementCounter(state);
        state.cartLoading = false;
        if (action?.error?.message == quoteErrorMessage) {
          // TODO: Show failure message in drawer itself
          // Router.replace('/checkout/cartempty');
        } else {
          Router.replace('/something-went-wrong');
        }
      })
      .addCase(addItemToQuote.pending, (state) => {
        state.cartLoading = true;
        // incrementCounter(state);
      })
      .addCase(addItemToQuote.fulfilled, (state, action) => {
        state.cartLoading = false;
        // decrementCounter(state);
        // const { RF_QUICKSTART, RF_60CT } = productsKeyConstants;
        // let canRedirect = ![RF_QUICKSTART, RF_60CT].includes(
        //   action.meta.arg.data.key
        // );
        // Router.push('/checkout');
      })
      .addCase(addItemToQuote.rejected, (state, action) => {
        // decrementCounter(state);
        state.cartLoading = false;
        if (action?.error?.message == quoteErrorMessage) {
          // TODO: Show failure message in drawer itself
          // Router.replace('/checkout/cartempty');
        } else {
          Router.replace('/something-went-wrong');
        }
      })
      .addCase(updateProduct.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.cartLoading = false;
      })
      .addCase(updateProduct.rejected, (state) => {
        state.cartLoading = false;
      })
      .addCase(getAbandonedData.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getAbandonedData.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getAbandonedData.rejected, (state, action) => {
        decrementCounter(state);
        if (action?.error?.message == quoteErrorMessage) {
          Router.replace('/checkout/cartempty');
          setTimeout(() => {
            toast.error(
              'We’re sorry, it looks like this cart is no longer available. Please proceed to the homepage to continue shopping.',
              {
                theme: 'colored',
                autoClose: 6000,
              }
            );
          }, 1000);
        } else {
          Router.replace('/something-went-wrong');
        }
      })
      .addCase(addCoupon.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(addCoupon.rejected, (state) => {
        decrementCounter(state);
      })

      .addCase(getQuote.pending, (state) => {
        // incrementCounter(state);
      })
      .addCase(getQuote.fulfilled, (state) => {
        state.showCart = true;
        // decrementCounter(state);
        toast.dismiss();
      })
      .addCase(getQuote.rejected, (state, action) => {
        toast.dismiss();
        // decrementCounter(state);
        if (action?.error?.message == quoteErrorMessage) {
          Router.replace('/checkout/cartempty');
        } else {
          toast.error(action?.error?.message || 'Something went wrong!', {
            theme: 'colored',
            autoClose: false,
          });
        }
      })
      .addCase(updateFirstLastNames.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(updateFirstLastNames.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(updateFirstLastNames.rejected, (state, action) => {
        decrementCounter(state);
      })
      .addCase(updateTax.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(updateTax.fulfilled, (state) => {
        decrementCounter(state);
        toast.dismiss();
      })
      .addCase(updateTax.rejected, (state, action) => {
        toast.dismiss();
        decrementCounter(state);
        if (action?.error?.message == quoteErrorMessage) {
          Router.replace('/checkout/cartempty');
        } else {
          toast.error('Unable to fetch tax information!', {
            theme: 'colored',
            autoClose: false,
          });
        }
      })
      .addCase(placeOrder.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(placeOrder.fulfilled, (state) => {
        // ! Temporary fix for redirection delay. To be refactored
        setTimeout(() => {
          store.dispatch(decrementAwaitingApiCounter());
        }, 3000);
        // ? Might be causing a delay. Toast not needed as page is redirected
      })
      .addCase(placeOrder.rejected, (state, action) => {
        toast.dismiss();
        decrementCounter(state);
        if (
          action?.error?.message ==
          "Active Quote for the requested quoteId doesn't exist. Please try again with valid quoteId"
        ) {
          Router.replace('/checkout/cartempty');
        } else {
          toast.error(action?.error?.message || 'Order Failed!', {
            theme: 'colored',
            autoClose: false,
          });
        }
      })
      .addCase(placeNewOrder.pending, (state) => {
        incrementCounter(state);
        state.hideSummary = true;
      })
      .addCase(placeNewOrder.fulfilled, (state) => {
        // ! Temporary fix for redirection delay. To be refactored
        setTimeout(() => {
          store.dispatch(decrementAwaitingApiCounter());
          store.dispatch(isHideSummary(false));
        }, 3000);
        toast.dismiss();
        // ? Might be causing a delay. Toast not needed as page is redirected
      })
      .addCase(placeNewOrder.rejected, (state, action) => {
        toast.dismiss();
        decrementCounter(state);
        state.hideSummary = false;
        // toast.error(action?.error?.message || 'Order Failed!', {
        //   theme: 'colored',
        //   autoClose: false,
        // });
      })
      .addCase(insertSurvey.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(insertSurvey.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(insertSurvey.rejected, (state, action) => {
        decrementCounter(state);
        toast.error(action?.error?.message || 'Something went wrong!', {
          theme: 'colored',
        });
      })
      .addCase(submitSupportTicket.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(submitSupportTicket.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(submitSupportTicket.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(loginUser.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(loginUser.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(loginUser.rejected, (state, action) => {
        decrementCounter(state);
        let message =
          action?.error?.message == errorMessageForInvalidLogin ? (
            <p>
              The account sign-in was incorrect. Please try again or reach out
              to our{' '}
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                <Link href={'/support'}>Support team </Link>{' '}
              </span>
              for additional help.
            </p>
          ) : (
            action?.error?.message
          );

        toast.error(message || 'Something went wrong!', {
          toastId: action?.error?.message,
          theme: 'colored',
        });
      })
      .addCase(resetPasswordLink.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(resetPasswordLink.fulfilled, (state, action) => {
        decrementCounter(state);
        toast.success(
          action?.payload?.result ||
            'If there is an account associated with this email you will receive a link to reset your password.',
          {
            theme: 'colored',
          }
        );
      })
      .addCase(resetPasswordLink.rejected, (state, action) => {
        decrementCounter(state);
        toast.error(action?.error?.message || 'Something went wrong!', {
          theme: 'colored',
        });
      })
      .addCase(resetPassword.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(resetPassword.fulfilled, (state) => {
        decrementCounter(state);
        toast.success(SUCCESS_MESSAGES.ACCOUNT_INFORMATION, {
          theme: 'colored',
        });
      })
      .addCase(resetPassword.rejected, (state, action) => {
        decrementCounter(state);
        let err = action?.error?.message;
        if (err == SUCCESS_MESSAGES.CURRENTPASSWORD_MISMATCH) {
          err = SUCCESS_MESSAGES.PASSWORD_MISMATCH;
        }
        toast.error(err || 'Something went wrong!', {
          theme: 'colored',
        });
      })
      .addCase(updateSubscriptionData.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(updateSubscriptionData.fulfilled, (state, action) => {
        decrementCounter(state);
        state.subscriptionStatusMsg = {
          status: 'success',
          message: handleUpdateSubFeedback(action.payload, true),
        };
        toast.success(handleUpdateSubFeedback(action.payload, true), {
          theme: 'colored',
        });
      })
      .addCase(updateSubscriptionData.rejected, (state, action) => {
        state.drawerShow = false;
        decrementCounter(state);
        state.subscriptionStatusMsg = {
          status: 'error',
          message: action?.payload
            ? handleUpdateSubFeedback(action.payload, false)
            : action?.error?.message,
        };
        toast.error(
          action?.payload
            ? handleUpdateSubFeedback(action.payload, false)
            : action?.error?.message,
          {
            toastId: action?.error?.message,
            theme: 'colored',
          }
        );
      })
      .addCase(subscriptionPaymentDetails.fulfilled, (state) => {
        decrementCounter(state);
        toast.success(SUCCESS_MESSAGES.PAYMENT_UPDATE, {
          theme: 'colored',
        });
      })
      .addCase(subscriptionPaymentDetails.rejected, (state, action) => {
        decrementCounter(state);
        toast.error(action?.error?.message || 'Something went wrong!', {
          toastId: action?.error?.message,
          theme: 'colored',
        });
      })
      .addCase(subscriptionPaymentDetails.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getOrderDetails.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getOrderDetails.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        decrementCounter(state);
        let message = action?.error?.message;
        toast.error(message || 'Something went wrong!', {
          toastId: action?.error?.message,
          theme: 'colored',
        });
        if (message == 'Access Denied: Please log in to access this page.') {
          Router.replace('/account/orders');
        }
        Router.replace('/account');
      })
      .addCase(getListAllOrders.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getListAllOrders.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getListAllOrders.rejected, (state, action) => {
        decrementCounter(state);
        toast.error(action?.error?.message || 'Something went wrong!', {
          toastId: action?.error?.message,
          theme: 'colored',
        });
      })
      .addCase(getSubscriptionDetails.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getSubscriptionDetails.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getSubscriptionDetails.rejected, (state, action) => {
        decrementCounter(state);
        let message = action?.error?.message;
        if (action?.error?.message == 'Customer Id was not matched') {
          message = 'Access Denied: Please log in to access this page.';
        }
        toast.error(message || 'Something went wrong!', {
          toastId: action?.error?.message,
          theme: 'colored',
        });
        Router.replace('/account');
      })
      .addCase(removeItemFromCart.pending, (state) => {
        state.cartLoading = true;
        // incrementCounter(state);
      })
      .addCase(removeItemFromCart.fulfilled, (state) => {
        state.cartLoading = false;
        // decrementCounter(state);
      })
      .addCase(removeItemFromCart.rejected, (state) => {
        state.cartLoading = false;
        state.brokenCart = true;
        // decrementCounter(state);
      })
      .addCase(prepareCart.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(prepareCart.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(prepareCart.rejected, (state, action) => {
        decrementCounter(state);
        if (
          action?.error?.message ==
          "Active Quote for the requested quoteId doesn't exist. Please try again with valid quoteId"
        ) {
          Router.replace('/checkout/cartempty');
        }
      })
      .addCase(resetPasswordV2.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(resetPasswordV2.fulfilled, (state, action) => {
        setToken(action.payload);
        decrementCounter(state);
      })
      .addCase(resetPasswordV2.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(quantityWarning.fulfilled, (state, action) => {
        state.quantityWarning = action.payload;
      })
      .addCase(getActiveSubscriptions.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getActiveSubscriptions.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getActiveSubscriptions.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getAllOrdersStatus.pending, (state) => {
        state.shippingInsights = true;
      })
      .addCase(getAllOrdersStatus.fulfilled, (state) => {
        state.shippingInsights = false;
      })
      .addCase(getAllOrdersStatus.rejected, (state) => {
        state.shippingInsights = false;
      })
      .addCase(getOrdersEvents.pending, (state) => {
        state.trackingEvents = true;
      })
      .addCase(getOrdersEvents.fulfilled, (state) => {
        state.trackingEvents = false;
      })
      .addCase(getOrdersEvents.rejected, (state) => {
        state.trackingEvents = false;
      })
      .addCase(getPostWithPagination.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getPostWithPagination.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostWithPagination.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostByCategory.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getPostByCategory.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostByCategory.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostListSingle.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getPostListSingle.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostListSingle.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostByTag.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getPostByTag.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getPostByTag.fulfilled, (state) => {
        decrementCounter(state);
      });
  },
});

export const {
  incrementAwaitingApiCounter,
  decrementAwaitingApiCounter,
  isManualLogout,
  showCart,
  disableBrokenCart,
  isHideSummary,
  isDrawerOpen,
  clearSubscriptionStatusMsg,
} = uiSlice.actions;

export const getUI = (state) => state.ui;
export const getManualLogout = (state) => state.ui.manualLogout;
export const isCartOpen = (state) => state.ui.showCart;

export default uiSlice.reducer;
